@import '../styles/constants.scss';

.houseImage {
    border-radius: $box-radius $box-radius 0 0;
}

.infobox {
    padding: 0.75rem;
    border-top: 1px solid #e2e2e2;
}

.year {
    margin: 0 0 0 0.75rem;
}

.coast {
    margin: 0;
    border-left: 1px solid #e2e2e2;
}

.venue {
    margin: 0 0.75rem 0 0;
    border-left: 1px solid #e2e2e2;
    // TODO: Think about venues that are longer than the width.
}

.thirtywidth {
    width: 30%;
}

.housetitle {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    
    @media (max-width: 1023px) {
        margin-bottom: 1.5rem;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0.8rem;
    }
}

.housedescription {
    height: 15rem;
    overflow-y: auto;
    overflow-x: hidden;
}


.vote {
    &:hover {
        background-color: #e66c2c !important;
    }
}