.body {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main {
    flex: 1;
}

.matchuphero {
    // The nav takes up 3.25rem so we just want to use the rest of the space.
    min-height: calc(100vh - 10.25rem);

    @media (max-width: 1023px) {
        min-height: auto;
        padding: 1rem 1.5rem;
    }
}

.next {
    &:hover {
        background-color: #e66c2c !important;
    }
}

.bar {
    &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.houselink {
    text-decoration: underline;
    text-decoration-thickness: 3px;
}