.body {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.titlewithline {
    width: 90%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin-left: auto;
    margin-right: auto;

    span {
        background: #fff;
        padding: 0 10px;
    }
}

.detailscontainer {
    display: flex;
    flex-direction: row;
    height: 110px;
    border: 1px solid #0A2623;
    margin: 1.5rem;
    border-radius: 5px;
}

a.detailsbox .detailsvalue {
    text-decoration: underline;
}

.detailsbox {
    flex: 1;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.detailsvalue {
    flex: 1;
    align-content: center;
    font-size: larger;
    font-weight: bold;
}

.detailslabel {
    font-size: small;   
}

.white {
    color: #0A2623;
}

.red {
    background-color: #D93240;
    color: white;

    &:hover {
        background-color: #D93240;
        color: white;
    }
}

.blue {
    background-color: #15403B;
    color: white;

    &:hover {
        background-color: #15403B;
        color: white;
    }
}

.yellow {
    background-color: #F2DA5E;
    color: #0A2623;
}

.green {
    background-color: #39FF14;
    color: #0A2623;
}

.score {
    height: 75px;
    width: 75px;
    font-size: 1.5rem;
    border-radius: 50%;
    text-align: center;
    padding-top: 1.2rem;
    position: absolute;
    bottom: 10%;
    right: 4%;

    .scorefiller {
        font-size: 0.75rem;
    }

    @media (min-width: 768px) {
        font-size: 1.5rem;
        padding-top: 1.8rem;
        height: 100px;
        width: 100px;
    }
}

.houseimage {
    position: relative;
}

$mobile-margin: 0.5rem;
$desktop-margin: 3rem;

.graphmargin {
    margin: 0 $mobile-margin $mobile-margin $mobile-margin;

    @media (min-width: 768px) {
        margin: 0 $desktop-margin $desktop-margin $desktop-margin;
    }
}

.controlmargin {
    margin: 0 0 $mobile-margin/2 $mobile-margin;

    @media (min-width: 768px) {
        margin: 0 0 $desktop-margin/2 $desktop-margin;
    }
}