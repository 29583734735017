.fixedbottom {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
  }
  
  .main {
    @media (min-width: 1024px) {
      // For whatever reason, if I add this to the touch component,
      // I lose the bottom padding.
      height: 100vh;
      display: flex;
      flex-direction: column;
    }
  
    // This adds the space taken up by the filter button.
    @media (max-width: 1023px) {
      padding-bottom: 4.5rem;
    }
  }
  
  .content {
    @media (min-width: 1024px) {
      padding-top: 1rem;
      flex: 1;
    }
  }
  
  .vs {
    color: hsl(348, 86%, 43%);
  
    @media (min-width: 1024px) {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: hsl(348, 100%, 61%);
      color: hsl(0, 0%, 96%);
      text-align: center;
      padding-top: 0.8rem;
      animation: rotate 5s linear infinite;
    }
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
  
    25% {
      transform: rotate(10deg);
    }
  
    50% {
      transform: rotate(0deg);
    }
  
    75% {
      transform: rotate(-10deg);
    }
  
    100% {
      transform: rotate(0deg);
    }
  }
  