.reviewrow {
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem 0;
    white-space: break-spaces;

    &:nth-child(even) {
        background-color:  hsl(0, 0%, 91%);
    }

    &:nth-child(odd) {
        background-color: hsl(0, 0%, 95%);
    }
}

.secondarytext {
    font-size: 0.9rem;
}

.starUser {
    background-color: rgba(244, 236, 164) !important;

    a {
        text-decoration: underline;
    }
}