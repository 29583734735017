.score {
    height: 75px;
    width: 75px;
    font-size: 1.5rem;
    border-radius: 50%;
    text-align: center;
    padding-top: 1.2rem;
    position: absolute;
    bottom: 12px;
    right: 10px;

    .scorefiller {
        font-size: 0.75rem;
        @media (min-width: 768px) {
            font-size: 0.5rem;
        }
    }

    @media (min-width: 768px) {
        font-size: 1rem;
        padding-top: 0.8rem;
        height: 50px;
        width: 50px;
    }
}

.houseimage {
    position: relative;
    @media (min-width: 768px) {
        max-height: 640px;
        max-width: 280px;
    }
}

.houseinfo {
    @media (max-width: 767px) {
        padding-top: 0;
    }
}

.houselink {
    text-decoration: underline;
    text-decoration-thickness: 3px;
}